.modal-backdrop {
  opacity: 0.1; 
}

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
